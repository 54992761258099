import { render, staticRenderFns } from "./CreditHistory.vue?vue&type=template&id=2dd2e370&scoped=true&"
import script from "./CreditHistory.vue?vue&type=script&lang=js&"
export * from "./CreditHistory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dd2e370",
  null
  
)

export default component.exports