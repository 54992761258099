<template>
  <div>
    <b-sidebar
      ref="sb"
      v-model="state"
      v-resize="onResize"
      :width="`${width}px`"
      :no-header-close="true"
      right
      shadow
      no-close-on-esc
      no-close-on-backdrop
    >
      <div v-if="state" class="bg-white px-1 px-lg-3">
        <data-layout
          v-if="userId"
          ref="dt"
          :fields="fields"
          :filter="filter"
          :order="order"
          :repo="repo"
          :parent-id="userId"
          :height="height"
          :limit="50"
          card-collapse
          @on-filter-submit="onFilterSubmit"
          @on-sort="onSort"
        >
          <template #card(header)="{ data: { item }}">
            <div>{{ item.t_type }}</div>
            <div class="small text-muted">
              <span v-shtml="$dateFormat(item.created_at)" />
            </div>
            <div class="small">
              <span>
                <font-awesome-icon icon="euro-sign" />
                {{ item.amount }}
              </span>
            </div>
          </template>
          <template #cell(additionalInfo)="{ data }">
            <span
              v-shtml="data.field.formatter(null, null, data.item)"
              class="d-block"
              style="min-width: 260px;"
            />
          </template>
          <template #cell(created_at)="{ data }">
            <span v-shtml="data.field.formatter(data.item.created_at, data.key, data.item)" />
          </template>
        </data-layout>
      </div>
      <template #header>
        <b-link class="mr-2 text-info" @click="onCancel">
          <font-awesome-icon v-if="isMobile" :icon="['fas', 'arrow-left']" class="mr-2" />
          <span v-shtml="parentTitle" />
        </b-link>
        - {{ displayTitle }}
      </template>
      <template #footer>
        <div v-if="!isMobile" class="d-flex bg-light align-items-center px-3 py-2">
          <strong class="mr-auto" />
          <b-btn variant="secondary" size="sm" @click="onCancel">
            {{ $t('eDoc_form_a_close') }}
          </b-btn>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isServer } from '@/mixins/helpers'

export default {
  name: 'CreditHistory',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    userId: {
      type: [String, Number],
      required: true
    },
    idx: {
      type: Number,
      default: -1
    },
    parentTitle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      repo: 'customers.transactions',
      fields: [
        {
          key: 'id',
          label: this.$t('fCustomer_cred_f_id'),
          sortable: true,
          class: 'col-1',
          thClass: 'text-center'
        },
        {
          key: 'created_at',
          label: this.$t('fCustomer_cred_f_date'),
          sortable: true,
          class: 'col-2',
          thClass: 'text-center',
          formatter: (value) => {
            return this.$dateFormat(value, 'DD/MM/YYYY<br>HH:mm')
          }
        },
        {
          key: 'amount',
          label: this.$t('fCustomer_cred_f_amount'),
          sortable: true,
          class: 'col-1 text-right',
          thClass: 'text-center',
          formatter: (value, key, item) => {
            const formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'EUR'
            })
            return formatter.format(item.amount)
          }
        },
        {
          key: 'type',
          label: this.$t('fCustomer_cred_f_package'),
          sortable: true,
          class: 'col-2 text-center',
          thClass: 'text-center',
          formatter: (value, key, item) => {
            let typeStr
            if (item.type === 'credits') {
              typeStr = this.$t('fCustomer_cred_t_credits')
            } else if (item.type === 'bonus') {
              typeStr = this.$t('fCustomer_cred_t_bonusCredits')
            } else {
              typeStr = item.type
            }
            return typeStr
          }
        },
        {
          key: 't_type',
          label: this.$t('fCustomer_cred_f_reason'),
          sortable: true,
          class: 'col-1',
          thClass: 'text-center',
          formatter: (value, key, item) => {
            return `${item.t_type} ${
              item.t_type === 'addon' && item.addon && item.addon.addonPackage
                ? `(${item.addon.addonPackage.name})`
                : ''
            }`
          }
        },
        {
          key: 'additionalInfo',
          label: this.$t('fCustomer_cred_f_additionalInfo'),
          thClass: 'text-center',
          tdClass: 'text-wrap',
          formatter: (value, key, item) => {
            const pairs = []
            if (item && item.additional_info) {
              Object.keys(item.additional_info).forEach((k) => {
                if (k === 'device_info' && item.additional_info[k]) {
                  const info = item.additional_info[k]
                  pairs.push('<br><b>Device data:</b><br>')
                  Object.keys(info).forEach((label) => {
                    if (label !== 'browser_language') {
                      pairs.push(`<b>${label}:</b> ${info[label]}`)
                    } else {
                      pairs.push('<b>Browser languages: </b>')
                      info[label].forEach(lang => pairs.push(`<span>${lang}</span>`))
                    }
                  })
                  pairs.push('<br/>')
                } else {
                  pairs.push(`<b>${k}</b> ${item.additional_info[k]}`)
                }
              })
            }
            if (item && item.staff) {
              pairs.push(`<b>staff</b> ${item.staff.email}`)
            }
            return pairs.join(' ')
          },
          cardFullWidth: true
        }
      ],
      filterFields: [
        {
          label: this.$t('fCustomer_cred_s_startDate'),
          key: 'start_date',
          type: 'date',
          class: 'col-2'
        },
        {
          label: this.$t('fCustomer_cred_s_finishDate'),
          key: 'finish_date',
          type: 'date',
          class: 'col-2'
        },
        {
          label: this.$t('fCustomer_cred_s_type'),
          key: 'transactions.type',
          type: 'select',
          class: 'col-2'
        },
        {
          label: this.$t('fCustomer_cred_s_reason'),
          key: 'transactions.t_type',
          type: 'select',
          class: 'col-2'
        }
      ],
      filterState: false,
      filter: [],
      order: { by: 'created_at', dir: 'desc' },
      state: this.value,
      displayTitle: this.$t('fCustomer_cred_title'),
      height: 500,
      width: 300
    }
  },
  async fetch () {
    const query = {}
    if (this.filterFields.length && this.value) {
      if (this.tFilter && this.tFilter.length) {
        for (const filter of this.filter) {
          query[`filter[${filter.column}]`] = filter.value
        }
      }
      await this.call({
        repo: this.repo,
        method: 'filterData',
        parentId: this.userId,
        query
      })
    }
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName',
      deviceInfo: 'device',
      tableLayout: 'tableLayout'
    }),
    isMobile () {
      return this.deviceInfo?.type === 'mobile' || false
    },
    filterData () {
      return this.getByName(`${this.repo}/filterData`)
    }
  },
  watch: {
    value (n) {
      this.state = n
      if (this.state) {
        this.$fetch()
      }
      this.$nextTick(() => {
        this.calcHeight()
        this.calcWidth()
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.calcHeight()
      this.calcWidth()
    })
  },
  beforeDestroy () {},
  methods: {
    ...mapActions({
      call: 'repos/call'
    }),
    onFilterSubmit (filter) {
      this.filter = filter
      this.$nextTick(async () => {
        await this.$fetch()
        this.$refs.dt.$fetch()
      })
    },
    onSort (order) {
      this.order = order
    },
    toggleFilter () {
      this.filterState = !this.filterState
    },
    onCancel () {
      this.$emit('input', false)
    },
    calcHeight () {
      if (!isServer && window) {
        this.$nextTick(() => {
          const $sidebar = this.$refs.sb?.$el?.querySelector('.b-sidebar-body')
          const $pager = this.$refs.sb?.$el?.querySelector('.pager')
          const sidebarHeight = $sidebar?.clientHeight || 0
          const pagerHeight = $pager?.clientHeight || 0
          // calc
          if (sidebarHeight > 0) {
            this.height = sidebarHeight - pagerHeight
          }
        })
      }
    },
    calcWidth () {
      let width = 0
      if (!isServer) {
        if (this.deviceInfo?.type === 'mobile') {
          width = document.body.offsetWidth
        } else {
          width = Math.round(document.body.offsetWidth * 0.80) - (15 * (this.idx + 1))
        }
      }
      this.width = width
    },
    onResize () {
      this.calcHeight()
      this.calcWidth()
    }
  }
}
</script>

<style scoped>

</style>
