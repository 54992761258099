<template>
  <b-card v-if="isExists" no-body class="mt-3 mb-1">
    <b-tabs pills card>
      <b-tab
        v-if="hostessCostLog.length"
        :title="$t('fCustomer_costLog_title')"
        active
      >
        <cost-log
          :idx="idx"
          :item-id="itemId"
          :items="hostessCostLog"
        />
      </b-tab>
      <b-tab
        v-if="hostessPrivacyLog.length"
        :title="$t('fCustomer_privacyLog_title')"
      >
        <privacy-log
          :idx="idx"
          :item-id="itemId"
          :items="hostessPrivacyLog"
        />
      </b-tab>
      <b-tab
        v-if="userClubAccessLog.length"
        :title="$t('fCustomer_clubAccessLog_title')"
      >
        <club-access-log
          :idx="idx"
          :item-id="itemId"
          :items="userClubAccessLog"
        />
      </b-tab>
      <b-tab
        v-if="statusLog.length"
        :title="$t('fCustomer_statusLog_title')"
      >
        <status-log
          :idx="idx"
          :item-id="itemId"
          :items="statusLog"
        />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import CostLog from './Elements/Logs/CostLog'
import PrivacyLog from './Elements/Logs/PrivacyLog'
import StatusLog from './Elements/Logs/StatusLog'
import ClubAccessLog from '~/components/forms/CustomerEditForm/Elements/Logs/ClubAccessLog'
import { mapComputedStateFields } from '~/mixins/globalForm'

export default {
  name: 'Logs',
  components: {
    ClubAccessLog,
    CostLog,
    PrivacyLog,
    StatusLog
  },
  props: {
    itemId: {
      type: [String, Number],
      required: true
    },
    idx: {
      type: Number,
      default: -1
    }
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName'
    }),
    ...mapComputedStateFields('customerEdit', [
      'logs'
    ]),
    isExists () {
      return this.logs && Object.keys(this.logs).length > 0
    },
    hostessCostLog () {
      return this.logs?.hostessCost || []
    },
    hostessPrivacyLog () {
      return this.logs?.hostessPrivacy || []
    },
    userClubAccessLog () {
      return this.logs?.userClubAccess || []
    },
    statusLog () {
      return this.logs?.status || []
    }
  }
}
</script>

<style scoped>

</style>
