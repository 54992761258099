var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"sb",attrs:{"width":(_vm.width + "px"),"no-header-close":true,"right":"","shadow":"","no-close-on-esc":"","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-link',{staticClass:"mr-2 text-info",on:{"click":_vm.onCancel}},[(_vm.isMobile)?_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['fas', 'arrow-left']}}):_vm._e(),_vm._v(" "),_c('span',{directives:[{name:"shtml",rawName:"v-shtml",value:(_vm.parentTitle),expression:"parentTitle"}]})],1),_vm._v("\n      - "+_vm._s(_vm.displayTitle)+"\n    ")]},proxy:true},{key:"footer",fn:function(){return [(!_vm.isMobile)?_c('div',{staticClass:"d-flex bg-light align-items-center px-3 py-2"},[_c('strong',{staticClass:"mr-auto"}),_vm._v(" "),_c('b-btn',{attrs:{"variant":"secondary","size":"sm"},on:{"click":_vm.onCancel}},[_vm._v("\n          "+_vm._s(_vm.$t('eDoc_form_a_close'))+"\n        ")])],1):_vm._e()]},proxy:true}]),model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[(_vm.state)?_c('div',{staticClass:"bg-white px-1 px-lg-3"},[_c('b-tabs',{attrs:{"pills":"","card":false,"nav-wrapper-class":"py-2","content-class":"px-0"},on:{"activate-tab":_vm.onShowTab},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab',{attrs:{"title":((_vm.$t('fCustomer_invt_receivedTitle')) + " (" + (_vm.info.invites.received) + ")"),"active":""}},[(_vm.userId && _vm.activeTab === 0)?_c('data-layout',{ref:"dtReceived",attrs:{"fields":_vm.receivedFields,"filter":_vm.filterReceived,"repo":_vm.repo,"parent-id":_vm.userId,"height":_vm.height,"limit":50,"card-collapse":""},on:{"on-sort":_vm.onSort},scopedSlots:_vm._u([{key:"card(header)",fn:function(ref){
var item = ref.data.item;
return [(item)?_c('user-profile-link',{attrs:{"user":item.user}}):_vm._e(),_vm._v(" "),(item)?_c('div',{staticClass:"small"},[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$dateFormat(item.created_at)))])]):_vm._e()]}},{key:"cell(user_name)",fn:function(ref){
var data = ref.data;
return [_c('user-profile-item',{attrs:{"user":data.item.user}})]}}],null,false,3436622132)}):_vm._e()],1),_vm._v(" "),_c('b-tab',{attrs:{"title":((_vm.$t('fCustomer_invt_sentTitle')) + " (" + (_vm.info.invites.sent) + ")")}},[(_vm.userId && _vm.activeTab === 1)?_c('data-layout',{ref:"dtSent",attrs:{"fields":_vm.sentFields,"filter":_vm.filterSent,"repo":_vm.repo,"parent-id":_vm.userId,"height":_vm.height,"limit":50,"card-collapse":""},on:{"on-sort":_vm.onSort},scopedSlots:_vm._u([{key:"card(header)",fn:function(ref){
var item = ref.data.item;
return [(item)?_c('user-profile-link',{attrs:{"user":item.invitee}}):_vm._e(),_vm._v(" "),(item)?_c('div',{staticClass:"small"},[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$dateFormat(item.created_at)))])]):_vm._e()]}},{key:"cell(invitee_name)",fn:function(ref){
var data = ref.data;
return [_c('user-profile-item',{attrs:{"user":data.item.invitee}})]}}],null,false,1225026729)}):_vm._e()],1)],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }